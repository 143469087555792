@font-face {
  font-family: 'Soehne Buch';
  src: url('./soehne-buch.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

:root {
  --layout: minmax(max-content, 1fr) 3fr;
  --color: #cae6ef;
}

@media screen and (max-width: 600px) {
  :root {
    --layout: 1fr;
  }
}

html {
  font-size: 112.5%;
}

body {
  background-color: var(--color);
  margin: 0;
  font-family: "Soehne Buch";
  line-height: 1.3;
  padding: 1rem;
}

main {
  display: grid;
  grid-row-gap: 4rem;
  grid-template-rows: auto auto 1fr auto;
  min-height: calc(100vh - 2rem);
  position: relative;
}

header {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: var(--layout);

  h2 {
    display: grid;
  }
}


h1, h2, h3, h4, h5, h6, p {
  font-size: 1rem;
  font-weight: normal;
  margin: 0;
}

p {
  max-width: 45ch;
}

#sb-orb {
  aspect-ratio: 1/1;
  height: 50vmin;
  left: 50%;
  opacity: .125;
  pointer-events: none;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;

  &:before,
  &:after {
    border-radius: 9999px;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:after {
    background-color: var(--color);
    filter: brightness(75%);
  }
}

section {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: var(--layout);
}

a {
  color: inherit;
}

footer {
  display: grid;
  grid-template-columns: var(--layout);
  grid-column-gap: 1rem;
  grid-row: 4;
}

section div,
footer div {
  display: grid;
  grid-column: 2;
  grid-column-gap: 2rem;
  grid-row-gap: .5rem;
  grid-template-columns: minmax(100px, max-content) 1fr;
}

@media screen and (max-width: 600px) {
  section div,
  footer div {
    grid-column: 1;
    grid-column-gap: 1rem;
  }
}